/**
 * fullView v1.1.2
 */
/*!
 * https://github.com/seeratawan01/fullview.js
 *
 * @license GPLv3 for open source use only
 *
 * Copyright (C) 2020 https://github.com/seeratawan01/fullview.js/blob/master/LICENSE
 */
@media (min-width: 993px) {
  *,
  *:before,
  *:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  html {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
  }
  body {
    overflow: hidden;
    height: 100%;
  }
  #fullview > * {
    display: block;
    height: 100vh !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000;
    overflow: hidden;
  }
  #fv-dots {
    position: fixed;
    top: 50%;
    left: 96%;
    transform: translate(-50%, -50%);
  }
  #fv-dots ul {
    display: block;
    list-style: none;
  }
  #fv-dots ul li {
    padding: 10px;
  }
  #fv-dots ul li a {
    display: block;
    margin: auto;
    width: 10px;
    height: 10px;
    text-align: center;
  }
  #fv-dots ul li a span {
    display: block;
    z-index: 1;
    cursor: pointer;
    text-decoration: none;
    background-color: #888888;
    border-radius: 50%;
    margin: 0 auto;
    width: 7px;
    height: 7px;
    transition: 0.1s ease all;
  }
  #fv-dots ul li a span:hover, #fv-dots ul li a span.active {
    width: 9px;
    height: 9px;
    background-color: #bdbdbd;
  }
  /* Tooltip container */
  .fv-tooltip {
    position: relative;
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
    /* Tooltip text */
  }
  .fv-tooltip .fv-tooltiptext {
    visibility: hidden;
    width: 120px;
    background: #3E474F;
    border-radius: 0.25em;
    color: #EDEFF0;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -1px;
    right: 105%;
  }
  .fv-tooltip .fv-tooltiptext:hover {
    visibility: visible;
  }
  .fv-tooltip .fv-tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #3E474F;
  }
  .fv-tooltip.fv-tooltip-left .fv-tooltiptext::after {
    left: unset;
    right: 100%;
    border-color: transparent #3E474F transparent transparent;
  }
}
